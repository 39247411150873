import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import indicator from '../../images/ntl/ntl_Indicator_1.svg'
import indicator2 from '../../images/ntl/ntl_Indicator2.svg'
import indicator3 from '../../images/ntl/ntl_Indicator3.svg'
import indicator4 from '../../images/ntl/ntl_Indicator4.svg'
import { StyledContainer } from '../layout'
import { displayFont, displayFontWeight, mainTextColor, Header2WithLeftBoarder, textFont } from './ntl_style'
import { getColOf3 } from '../text_style'
import { ZoomableImageWithModal } from './ntl_common_component'
import timeLine from "../../images/ntl/TimeLine.png"

let StyledLine = styled.div`

  font-family: ${textFont};
  font-size: 16px;
  line-height: 28px;
  color: ${mainTextColor};
  text-align: left;
`

let StepHeader = styled.div`

  font-family: ${displayFont};
  font-weight: ${displayFontWeight.regular};
  font-size: 24px;
  line-height: 34px;
  color: ${mainTextColor};
  text-align: left;
`


let Header2 = styled.div`
  font-family: OpenSans-Bold;
  font-size: 36px;
  color: #161616;
  letter-spacing: -0.5px;
`

let contentList1 = [
  "Literature review",
  "User Interview",
  "Contextual Inquiry"
]

let contentList2 = ["Xcode",
  "Android Studio",
  "Responsive Design",
]

let contentList3 = [
  "Obj-C for iOS",
  "Java for Android",
  "PHP",
  "MySQL"
]

let contentList4 = [
  "Design",
  "Development",
  "Evaluation",
  "Loop"
]


class Step extends React.Component{

  render(){
    return(
      <div>
        <StepHeader> {this.props.header} </StepHeader>

        <img src={this.props.indicator} style={{height:"6px", marginBottom:`5px`}} className="img-fluid" alt="Responsive image"/>

        {this.props.contentList.map(d => {
          return(
            <StyledLine> {d} </StyledLine>)
        })}
      </div>
    )
  }
}

class Process extends React.Component{

  render() {
    return (

      <StyledContainer fluid={true}>

        <Row style={{paddingBottom:`16px`}}>
          <Col md={3}> </Col>
          <Col md={6}> <Header2WithLeftBoarder> Process </Header2WithLeftBoarder></Col>
        </Row>

        <Row>
          <Col md={3}> </Col>
          <Col md={2}> <Step header={"Research"} contentList={contentList1} indicator={indicator} /> </Col>
          <Col md={2}> <Step header={"Ideation"} contentList={contentList2} indicator={indicator2} /> </Col>
          <Col md={2}> <Step header={"Prototype"} contentList={contentList3} indicator={indicator3} /> </Col>
          <Col md={2}> <Step header={"Development"} contentList={contentList4} indicator={indicator4} /> </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={6}>
            {/*I need to add some photos to it*/}
            <ZoomableImageWithModal image={timeLine} header={"Time Line"}/>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default Process