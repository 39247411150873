import React from 'react'
import {Row, Col, Container, Collapse, Card, CardHeader, CardBody} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'

import { StyledContainer } from '../layout'

import { AnimatedText, SectionIntroHeader } from './ntl_common_component'

import {
  Header4WithLeftBorder, displayFont, displayFontWeight, textFont, textFontWeight, MainText1
} from './ntl_style'

import TrackVisibility from 'react-on-screen';
import jjsailingImage from "../../images/ntl/JJ_On_the_lake.png"


class UserResearch extends React.Component{


  render(){

    return(
      <StyledContainer  fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>

            <TrackVisibility>
              <AnimatedText text={"Domain Research gave me a strong understanding of " +
              "the design requirements from my client. To better understand my end users, I conduct a user interview and later" +
              "a contextual inquiry."}/>
            </TrackVisibility>
          </Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header4WithLeftBorder> User Interview </Header4WithLeftBorder>
            {getSeperatorWithHeight(16)}

            <MainText1>
              I had the interview with a couple of sailors and researchers.
              My questions was related to how users act based on the data and
              what technologies they use. Below is the summary of their needs:

              <ol>
                <li style={{marginBottom: `4px`}}>
                  Need accurate real-time information
                </li>

                <li style={{marginBottom: `4px`}}>
                  Relatively bad internet connections
                </li>

                <li style={{marginBottom: `4px`}}>
                  People use different mobile devices
                </li>

              </ol>

            </MainText1>
          </Col>

        </Row>

        {getSeperatorWithHeight(24)}
        <Row>
          {getColOf3()}
          <Col md={6}>

          <Header4WithLeftBorder> Contextual Inquiry </Header4WithLeftBorder>
          {getSeperatorWithHeight(16)}
          </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={4}>

            <MainText1>
              After the ice on the lake melt, I went on the lake with my sailor
              friend. He showed me how to wind sail, and how to make sailing
              decisions based on different variables.
            </MainText1>

            {getSeperatorWithHeight(16)}

            <MainText1>
              That experience provided me a deeper understanding of how
              sailors might use data in my apps to work. They really cared
              about the wind speed and direction. Besides, they wanted the
              up-to-date information because wind changes frequently. I also
              found that the captain on a small boat usually made decisions
              quickly with little help from sophisticated data.
            </MainText1>
          </Col>

            <Col md={2}>
              <img src={jjsailingImage} />
            </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default UserResearch
