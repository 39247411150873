import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import {getSeperatorWithHeight} from "../text_style"
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder,Header4WithLeftBorder,MainText1} from './ntl_style'
import roadmap from "../../images/ntl/User_flow_software_engineering.png"
import { ZoomableImageWithModal } from './ntl_common_component'

class DevelopmentArchitecture extends React.Component{

  render(){

    return(

      <StyledContainer fluid={true}>

        <Row style={{paddingBottom:`32px`}}>
          <Col md={3}/>
          <Col md={4}>
            <Header4WithLeftBorder> Development Architecture </Header4WithLeftBorder>
          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          <Col md={3}/>
          <Col md={6}>
            <ZoomableImageWithModal image={roadmap} header={"Software Architecture"}/>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default DevelopmentArchitecture