import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import { getColOf3, getSeperatorWithHeight } from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'

import {SectionIntroHeader, AnimatedText} from './ntl_common_component'
import {MainText1} from './ntl_style'
import TrackVisibility from 'react-on-screen';


class RedesignIntro extends React.Component {

  render(){

    let data = {header: "Next Step",
      subtitle:"Improve the experience with more knowledge"}

    return(
      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <SectionIntroHeader header={data.header} subtitle={data.subtitle}/>
          </Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <AnimatedText text={"NTL Lake Condition is my first front-end/UX development experience. Three years later, " +
            "as a UX Designer, what can I do to further improve the user experience? " +
            "Below is my improvement to the old design presented in a high fidelity prototype."}/>
          </Col>
        </Row>

      </StyledContainer>

    )
  }
}

export default RedesignIntro