import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {DesignChallengeHeader, ImageCaption, MainText1, MainText1Bold} from './ntl_style'

import {SectionIntroHeader} from './ntl_common_component'
import oldDesign from '../../images/ntl/Old_Java_Menu.png'
import androidNewNavigation from "../../images/ntl/Android_new_navigation.png"


class DesignChallenges2 extends React.Component {

  render(){

    return(

      <StyledContainer fluid={true} style={{backgroundColor: "#FFF9F3"}}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <DesignChallengeHeader>
              How do users navigate among different lakes?
            </DesignChallengeHeader>
          </Col>
        </Row>
        {getSeperatorWithHeight(24)}
        <Row>
          {getColOf3()}
          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={oldDesign}/>
            <ImageCaption> Old Design with navigation list </ImageCaption>
          </Col>

          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={androidNewNavigation}/>
            <ImageCaption> New Design with navigation bar </ImageCaption>
          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}

          <Col md={6}>
            <MainText1>
              The original Android app asked users to navigate through three
              lakes by choosing from <MainText1Bold>a list </MainText1Bold>.
              That interaction design enables user to choose one lake he wants
              fast. However, In our research, sailors and fishers had no need to constantly switch lakes. Thus,
              my new design directly presents the lake information to users.
              They can navigate to other lakes by <MainText1Bold> swiping </MainText1Bold>. To get back to their
              lakes quickly if they ever leave the app, they can set up one page
              as their own landing page.

            </MainText1>
              {getSeperatorWithHeight(24)}

            <MainText1>
              One challenge in this design is to explain this functionality.
              On iOS, I designed a button with a label, but the
              space left for the font is small. On Android, I designed a
              message after the user clicks on a <MainText1Bold> button with star </MainText1Bold>. This message
              would tell users the usage of home page. To finally decide on the
              interaction method, I would need more information on users' interaction.
            </MainText1>

          </Col>

        </Row>




      </StyledContainer>

    )
  }
}

export default DesignChallenges2