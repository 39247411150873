import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import organImage from '../../images/ntl/Mendota.png'
import { StyledContainer } from '../layout'
import {Header2WithLeftBoarder, MainText1} from './ntl_style'

class ProblemStatement extends React.Component{

  render(){

    return(

      <StyledContainer fluid={true}>

        <Row style={{paddingBottom:`32px`}}>
          <Col md={3}/>
          <Col md={4}>
            <Header2WithLeftBoarder> Problem Statement </Header2WithLeftBoarder>
          </Col>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={4}>
            <MainText1>
              Researchers of the Center of Limnology in University of Wisconsin - Madison received much real-time weather data from sensors
              on different lakes. They want to share those data with sailors, fishermen, and other researchers as a outreach activity to the community.
              Thus, it hired me as a student researcher to develop mobile applications for users. At the same, I was taking Human-Computer Interaction
              in the semester, so I brought the practice of user-center design into this project.
            </MainText1>
          </Col>

          <Col md={2}>
            {/*Need change*/}
            <img src={organImage} className="img-fluid" style={{paddingLeft:`10px`}} alt="gift"/>
          </Col>
        </Row>


      </StyledContainer>

    )
  }
}

export default ProblemStatement