import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {DesignChallengeHeader, Header4, Header4WithLeftBorder, ImageCaption, MainText1, MainText1Bold} from './ntl_style'


class InteractivePrototype extends React.Component{

  render(){

    return(
      <StyledContainer fluid={true}>
        <Row>

          {getColOf3()}
          <Col md={6}>

            <Header4WithLeftBorder>
              Interactive Prototype
            </Header4WithLeftBorder>

          </Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={6} style={{textAlign: "center", height:"800px"}}>

            <iframe style={{border: "none", width:"100%", height:"800px"}}
                    src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FKBzjuS6N0Vab2dU3CnCmlVb5%2FPortfolio%3Fnode-id%3D10%253A109%26scaling%3Dscale-down"
                    allowFullScreen/>
          </Col>
        </Row>

      </StyledContainer>
    )

  }

}
export default InteractivePrototype