import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'
import productImage from '../../images/ntl/Lake_Condition.png'
import { StyledContainer } from '../layout'
import {
  Title,
  Acknowledgement,
  Brief,
  mainTextColor,
  accentColor,
  textFont,
  IntroLine,
  IntroSubHeader, ImageCaption,
} from './ntl_style'
import YouTube from "react-youtube"


class Intro extends React.Component{

  render(){
    return (
      <StyledContainer fluid={true}>

        <Row className={"highlight"}>
          <Col md={3}> </Col>
          <Col md={6}>
            <Title xs={6}> NTL Lake Condition </Title>
          </Col>
          <Col xs={3}/>
        </Row>

        <Row>
          <Col md={3}> </Col>
          <Col md={6}>
            <Acknowledgement md={6}> Individual Project for Center of Limnology, UW-Madison </Acknowledgement>
          </Col>

          <Col md={3}/>
        </Row>

        <Row style={{paddingTop: 48, paddingBottom: 48}}>
          <Col md = {3}> </Col>
          <Col md={6}>
            <Brief > Distributed the real-time weather data of multiple lakes to sailors, fishermen and researchers</Brief>
          </Col>
        </Row>

        <Row>
          <Col md={3}/>
          <Col md={3}>
            <IntroSubHeader style={{paddingBottom:12}}>My Role:</IntroSubHeader>

            <IntroLine>Designed the user interface</IntroLine>
            <IntroLine>Implemented for iOS and Android </IntroLine>
            <IntroLine>Conducted user research and evaluation</IntroLine>

          </Col>

          <Col md={3}>
            <IntroSubHeader style={{paddingBottom:12}}> Time: </IntroSubHeader>

            <IntroLine> Dec.2015 - Oct.2016</IntroLine>
          </Col>
          <Col md={3}/>
        </Row>

        <Row style={{paddingTop:40}}>
          <Col md={3}/>
          <Col md={6} style={{textAlign:"center"}}>
            {/*I need to add a photo to it*/}
            <img src={productImage}/>
          </Col>
        </Row>

        <Row>
          <Col md={3}>
          </Col>

          <Col md={6} className="d-flex justify-content-center">
            <ImageCaption style={{fontSize:"16px"}}>
              <a href={"https://itunes.apple.com/us/app/ntl-lake-conditions/id1097579027?mt=8"}> Click to check out the application in AppStore</a>
            </ImageCaption>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default Intro