import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import { getColOf3, getSeperatorWithHeight } from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'

import {SectionIntroHeader} from './ntl_common_component'
import {MainText1, Header4WithLeftBorder} from './ntl_style'
import Header from '../header'
import androidStudio from "../../images/ntl/AndroidStudio.png"
import ios from "../../images/ntl/iOS8.png"
import lollipop from "../../images/ntl/lollipop.png"
import xcode from "../../images/ntl/XCode.png"


class PlatformAndTool extends React.Component {

  render(){
    return(
      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
             <Header4WithLeftBorder>
              Platform and Tool
             </Header4WithLeftBorder>
          </Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={1} style={{textAlign: "center"}}>
            <img src={lollipop}/>
          </Col>

          <Col md={1} style={{textAlign: "center"}}>
            <img src={androidStudio}/>
          </Col>

          <Col md={1} style={{textAlign: "center"}}>
            <img src={ios}/>
          </Col>

          <Col md={1} style={{textAlign: "center"}}>
            <img src={xcode}/>
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default PlatformAndTool