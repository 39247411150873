import React from 'react'
import {Row, Col, Container, Collapse, Card, CardHeader, CardBody} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'

import { StyledContainer } from '../layout'

import { AnimatedText, SectionIntroHeader } from './ntl_common_component'

import {
  Header4WithLeftBorder, displayFont, displayFontWeight, textFont, textFontWeight, MainText1
} from './ntl_style'

import TrackVisibility from 'react-on-screen';
import userTaskImage from "../../images/ntl/User_task.png"


class DesignImplication extends React.Component{


  render(){

    return(
      <StyledContainer  fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>

            <TrackVisibility>
              <AnimatedText text={"Combined with all initial research, I had a design implication, and user tasks:"}/>
            </TrackVisibility>
          </Col>

        </Row>
        {getSeperatorWithHeight(16)}
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header4WithLeftBorder>
              Design Implication
            </Header4WithLeftBorder>

          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}
          <Col md={3}>
            <ul>
              <li>
                Display relevant data
              </li>

              <li>
                Allow for constant update
              </li>

              <li>
                Design for phones with different sizes
              </li>

              <li> Ensure the consistency within the platform, iOS and Android</li>

              <li>
                Display important missing data notification
              </li>
            </ul>
          </Col>

          <Col md={3}>
            <ul>
              <li>
                Present the data in a understandable manner
              </li>

              <li>
                Display two different Units: Scientific unit and British unit
              </li>

              <li>
                View data of different lakes
              </li>

              <li>
                Provide explanation about the data
              </li>

              <li>
                Easy for myself to implement
              </li>
            </ul>

          </Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header4WithLeftBorder>
              User Task
            </Header4WithLeftBorder>
          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}
          <Col md={6} style={{textAlign: "center"}}>
            <img src={userTaskImage} />
          </Col>
        </Row>

      </StyledContainer>
    )
  }
}

export default DesignImplication