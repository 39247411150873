import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {Header3,ImageCaption, AccordianMainText1} from './ntl_style'
import oldDesignImage from "../../images/ntl/Old_Java.png"
import { ZoomableImageWithModal } from './ntl_common_component'

class OldDesign extends React.Component{

  render(){
    return (
        <div>
          <Row>
            <Col md={8}>
              <AccordianMainText1>
              The old design includes all necessary functions, however the flaws in its design made it inconvenient to use and read.
              </AccordianMainText1>
              {getSeperatorWithHeight(20)}
              <AccordianMainText1>
              Problems:
              </AccordianMainText1>
              {getSeperatorWithHeight(12)}

              <AccordianMainText1>
                <ol>
                  <li style={{marginBottom:`4px`}}>
                    The color used makes it hard to use with the background
                  </li>

                  <li style={{marginBottom:`4px`}}>
                    Layout doesn’t separate the data well
                  </li>

                  <li style={{marginBottom:`4px`}}>
                    Need extra step to choose a lake, not fast
                  </li>

                  <li style={{marginBottom:`4px`}}>
                    This design doesn’t look well on different devices
                  </li>

                  <li>
                    The missing value looks awkward
                  </li>
                </ol>

              </AccordianMainText1>

            </Col>

            <Col md={4}>
              <ZoomableImageWithModal image={oldDesignImage} header={"Old Design"}/>
            </Col>

          </Row>

        </div>

    )

  }

}

export default OldDesign