import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, MainText1, getColOf3, getSeperatorWithHeight, HeaderForSubtitle,
  Header2WithLeftBoarderGift, HeaderForSubtitleWithLeftBorderGift} from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'

import {SectionIntroHeader} from './ntl_common_component'

class ResearchIntro extends React.Component {

  render(){

    let data = {header: "Research",
      subtitle:"Understand background and users of this project"}

    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <SectionIntroHeader header={data.header} subtitle={data.subtitle}/>
          </Col>
        </Row>


      </StyledContainer>

    )
  }
}

export default ResearchIntro