import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3} from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'

import {SectionIntroHeader} from './ntl_common_component'

class DesignIntro extends React.Component {

  render(){

    let data = {header: "Design",
      subtitle:"Iterative design combined with evaluation"}

    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <SectionIntroHeader header={data.header} subtitle={data.subtitle}/>
          </Col>
        </Row>


      </StyledContainer>

    )
  }
}

export default DesignIntro