import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {Header3,ImageCaption} from './ntl_style'
import dataImage from "../../images/ntl/Data.png"
import { ZoomableImageWithModal } from './ntl_common_component'

class DataVariables extends React.Component{

  render(){
    return (
        <div>

          <ZoomableImageWithModal image={dataImage} header={"Data variables"} />
          {getSeperatorWithHeight(8)}
          <ImageCaption>
            * Researchers of the limnology care all of those data
          </ImageCaption>

        </div>

    )

  }

}

export default DataVariables