import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components'
import { Layout } from '../components/layout'
import SEO from '../components/seo'
import { getSeperatorWithHeight } from '../components/text_style'
import Intro from '../components/ntl/ntl_intro'
import { SectionSeperator } from '../components/ntl/ntl_common_component'
import Process from '../components/ntl/ntl_process'
import ProblemStatement from '../components/ntl/ntl_problem_statement'
import ResearchIntro from '../components/ntl/ntl_research_intro'
import DomainResearch from '../components/ntl/ntl_domain_research'
import UserResearch from '../components/ntl/ntl_user_research'
import DesignImplication from '../components/ntl/ntl_design_implication'
import DesignIntro from '../components/ntl/ntl_design_intro'
import DesignChallenges1 from '../components/ntl/ntl_decisions'
import DesignChallenges2 from '../components/ntl/ntl_challenges2'
import DesignChallenges3 from '../components/ntl/ntl_challenges3'
import DesignChallenge4 from '../components/ntl/ntl_challenges4'
import PlatformAndTool from '../components/ntl/ntl_platform_and_tool'
import DevelopmentIntro from '../components/ntl/ntl_development_intro'
import DevelopmentArchitecture from '../components/ntl/ntl_development_architecture'
import RedesignIntro from '../components/ntl/ntl_redesign_intro'
import Improvement from '../components/ntl/ntl_hifi_feature'
import InteractivePrototype from '../components/ntl/ntl_interactive_prototype'
import Scrollspy from 'react-scrollspy'
import Footer from './footer'
import { Row } from 'reactstrap'

const NTLLakeCondition = () => (

  <Layout>

    <SEO title={"NTLLakeCondition"} keywords={['NTLLakeCondition', "Junjie Xu"]}/>

    {getSeperatorWithHeight(50)}
    <Scrollspy items={['overview', 'process', 'problem_statement', "research", "design", "development", "improvement"]}
               style={{position:"fixed", paddingTop:"48px", listStyleType:"none"}} currentClassName="is-current-ntl"
               scrolledPastClassName={"is-past-ntl"} offset = {-30}  className={"sidebar"}
    >
      <li className={"scollli"}><a href="#overview">Overview</a></li>
      <li className={"scollli"}><a href="#process">Process</a></li>
      <li className={"scollli"}><a href="#problem_statement">Problem Statement</a></li>
      <li className={"scollli"}><a href="#research">Research</a></li>
      <li className={"scollli"}><a href="#design">Design</a></li>
      <li className={"scollli"}><a href="#development">Development</a></li>
      <li className={"scollli"}><a href="#improvement">Improvement</a></li>
    </Scrollspy>

    <section id={"overview"}>
      <Intro/>
    </section>

    <section id={"process"}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator/>
      {getSeperatorWithHeight(100)}
      <Process/>

    </section>

    <section id={"problem_statement"}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator/>
      {getSeperatorWithHeight(100)}
      <ProblemStatement/>

    </section>

    <section id={"research"}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator/>
      {getSeperatorWithHeight(100)}
      <ResearchIntro/>

      {getSeperatorWithHeight(50)}
      <DomainResearch/>

      {getSeperatorWithHeight(50)}
      <UserResearch/>

      {getSeperatorWithHeight(50)}
      <DesignImplication/>
    </section>

    <section id={"design"}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator/>
      {getSeperatorWithHeight(100)}

      <DesignIntro/>

      {getSeperatorWithHeight(24)}
      <DesignChallenges1/>

      {getSeperatorWithHeight(40)}
      {getSeperatorWithHeight(40, "#FFF9F3")}
      <DesignChallenges2/>

      {getSeperatorWithHeight(40, "#FFF9F3")}
      {getSeperatorWithHeight(40)}
      <DesignChallenges3/>

      {getSeperatorWithHeight(40)}
      {getSeperatorWithHeight(40, "#FFF9F3")}
      <DesignChallenge4/>
      {getSeperatorWithHeight(40, "#FFF9F3")}

    </section>


    <section id={"development"}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator/>
      {getSeperatorWithHeight(100)}

      <DevelopmentIntro/>
      {getSeperatorWithHeight(50)}
      <PlatformAndTool/>
      {getSeperatorWithHeight(50)}
      <DevelopmentArchitecture/>

    </section>

    <section id={"improvement"}>
      {getSeperatorWithHeight(100)}
      <SectionSeperator/>
      {getSeperatorWithHeight(100)}

      <RedesignIntro/>

      {getSeperatorWithHeight(50)}
      <Improvement/>

      {getSeperatorWithHeight(50)}
      <InteractivePrototype/>

    </section>

    {getSeperatorWithHeight(200)}
    <Row className="d-flex justify-content-center">
      <a style={{color:`#ADADAD`}} href="#">Back to top</a>
    </Row>
    {getSeperatorWithHeight(20)}
    <Footer/>
  </Layout>

)

export default NTLLakeCondition

