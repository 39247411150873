import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import { getColOf3, getSeperatorWithHeight } from '../text_style'
import researchCroppedImage from '../../images/hci_foundation/Research-cropped.jpg'
import { StyledContainer } from '../layout'

import {SectionIntroHeader} from './ntl_common_component'
import {MainText1} from './ntl_style'

class DevelopmentIntro extends React.Component {

  render(){

    let data = {header: "Development",
      subtitle:"Realize the design and interaction with live data"}

    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <SectionIntroHeader header={data.header} subtitle={data.subtitle}/>
          </Col>
        </Row>

        {getSeperatorWithHeight(24)}

        <Row>
          {getColOf3()}
          <Col md={6}>
            <MainText1>
              As the full-stack developer, in addition to worrying about the experience people can see and interact, I also strived to provide users with a smooth real-world experience with my skills in programming, network knowledge and available tools.
            </MainText1>
          </Col>
        </Row>

      </StyledContainer>

    )
  }
}

export default DevelopmentIntro