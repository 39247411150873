import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {DesignChallengeHeader, Header4, ImageCaption, MainText1, MainText1Bold} from './ntl_style'

import { SectionIntroHeader, ZoomableImageWithModal } from './ntl_common_component'
import mainImage from "../../images/ntl/Lake_Mendota_Main_New.png"
import navigationImage from "../../images/ntl/Navigation.png"
import unitChangeImage from "../../images/ntl/Unit Change (1).png"
import errorImage from "../../images/ntl/error_new.png"


class Improvement extends React.Component {

  render(){
    return(
      <StyledContainer fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>
            <Header4> Main interface </Header4>
          </Col>

        </Row>

        <Row>
          {getColOf3()}
          <Col md={3}>

            <MainText1>
              This new design keeps using the grid system implemented in the old design.
              Much empathize is placed on numbers data. This color of blue
              creates the feeling of water and cool for the summer since it will be
              normally used in the summer
            </MainText1>
          </Col>

          <Col md={3}>
            <ZoomableImageWithModal image={mainImage} header={"Main Interface"}/>
          </Col>
        </Row>

        <Row>
          {getColOf3()}

          <Col md={3}>
            <ZoomableImageWithModal image={navigationImage } header={"User Navigation"}/>
          </Col>

          <Col md={3}>
            <MainText1>
              The navigation system of this design allows the user to switch to one lake by two ways:
              either choosing one from the list or swiping left or right to another lake.
            </MainText1>
          </Col>
        </Row>

        <Row>
          {getColOf3()}
          <Col md={3}>
            <MainText1>
              If the network is off, it is important for users to know that their data might be already out of date. Thus, I used the alert color "red". The same action also applies to the missing data where it is important for users to know that information provided can be inaccurate.
            </MainText1>
          </Col>

          <Col md={3}>
            <ZoomableImageWithModal image={unitChangeImage} header={"Unit Change"}/>
          </Col>
        </Row>

        <Row>
          {getColOf3()}

          <Col md={3}>
            <ZoomableImageWithModal image={errorImage} header={"Error Change"}/>
          </Col>

          <Col md={3}>
            <MainText1>
              If the network is off, it is important for users to know that their
              data might be already out of date. Thus, I used the alert color
              "red". The same action also applies to the missing data where
              it is important for users to know that information provided can be
              inaccurate.
            </MainText1>
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}

export default Improvement;
