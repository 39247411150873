import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {DesignChallengeHeader, ImageCaption, MainText1, MainText1Bold} from './ntl_style'

import {SectionIntroHeader} from './ntl_common_component'
import oldDesign from '../../images/ntl/ugly_design.png'
import gridDesign from "../../images/ntl/ios_main_screen.png"


class DesignChallenges3 extends React.Component {

  render(){

    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <DesignChallengeHeader>
              How to make the design responsive to different devices?
            </DesignChallengeHeader>
          </Col>
        </Row>
        {getSeperatorWithHeight(24)}
        <Row>
          {getColOf3()}
          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={oldDesign}/>
            <ImageCaption> Old Design with a list of text</ImageCaption>
          </Col>

          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={gridDesign}/>
            <ImageCaption> New Design with grid</ImageCaption>
          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}

          <Col md={6}>
            <MainText1>
              Creating a reasonable responsive design is challenging.
              Limited options for screen size on iOS makes it a little easier,
              because the worst case scenario, I could hard-coded the looking
              of UI phone by phone. However, on Android, preparing for all
              possibility of screen sizes is impossible. The options are
              basically unlimited. Thus, I had to come up with a better
              responsive design possible for different size. My first
              design was a <MainText1Bold> list of text </MainText1Bold>, but it doesn’t perform well when stretched
              out. Different line space doesn't look harmony with a fixed
              font size. Thus, based on some research, I choose <MainText1Bold> the grid
              design </MainText1Bold> for this app. The space between each cell will
              be stretched out based on different screen sizes, with a
              similar proportion. The increased space between cells
              looks better than increased line space.
            </MainText1>
          </Col>
        </Row>
      </StyledContainer>

    )
  }
}

export default DesignChallenges3