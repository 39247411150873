import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {DesignChallengeHeader, ImageCaption, MainText1, MainText1Bold} from './ntl_style'

import {SectionIntroHeader} from './ntl_common_component'
import iosDesign from '../../images/ntl/ios_main_screen.png'
import androidDesign from "../../images/ntl/Android_new_navigation.png"


class DesignChallenge4 extends React.Component {

  render(){

    return(

      <StyledContainer fluid={true} style={{backgroundColor:"#FFF9F3"}} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <DesignChallengeHeader>
              How to conform the design to different platforms, while keep it
              consistent?
            </DesignChallengeHeader>
          </Col>
        </Row>
        {getSeperatorWithHeight(24)}
        <Row>
          {getColOf3()}
          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={iosDesign}/>
            <ImageCaption> iOS Design</ImageCaption>
          </Col>

          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={androidDesign}/>
            <ImageCaption> Android Design</ImageCaption>
          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}

          <Col md={6}>
            <MainText1>
              Android and iOS each has its own unique design style.
              So to conform the guide and the feeling for each platform,
              I kept the main design the same but changed other elements
              to fit the styles of both platforms. Most notably, the navigation bar looks different. In iOS native design language,
              the swipe <MainText1Bold> navigation indicator </MainText1Bold> usually is represented as dots.
              However, in Android, the navigation bar has the title associated with each page.
              In addition, Android has an native design of a floating button, which serves the purpose of "home page" button pretty well.

            </MainText1>
          </Col>
        </Row>
      </StyledContainer>
    )
  }
}

export default DesignChallenge4