import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {getColOf3, getSeperatorWithHeight} from '../text_style'
import { StyledContainer } from '../layout'
import {DesignChallengeHeader, ImageCaption, MainText1, MainText1Bold} from './ntl_style'

import {SectionIntroHeader} from './ntl_common_component'
import oldDesign from '../../images/ntl/Old_Java_resized.png'
import iosMainScreen from "../../images/ntl/ios_main_screen.png"


class DesignChallenges1 extends React.Component {

  render(){

    return(

      <StyledContainer fluid={true} >
        <Row>
          {getColOf3()}
          <Col md={6}>
            <DesignChallengeHeader>
              How to make it easy to spot the data?
            </DesignChallengeHeader>
          </Col>
        </Row>
        {getSeperatorWithHeight(24)}
        <Row>
          {getColOf3()}
          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={oldDesign}/>
            <ImageCaption> Old Design with bad contrast </ImageCaption>
          </Col>

          <Col md={3} style={{textAlign: "center", paddingLeft: "5%", paddingRight: "5%"}}>
            <img src={iosMainScreen}/>
            <ImageCaption> New Design with big fonts </ImageCaption>
          </Col>
        </Row>

        {getSeperatorWithHeight(16)}

        <Row>
          {getColOf3()}

          <Col md={6}>
            <MainText1>
            The original design was like a checklist and not focus on highlighting
            the numbers. So my first design was to highlight the data users needed.
            I chose different color and font weight to distinguish between data and
            variable names. However, I noticed that my SMEs still complained about
            not be able to read the data. With more research, I realized that
              <MainText1Bold> ages of sailors </MainText1Bold> on the lake were across a wide range. Many old sailors
            had a old phone like iPhone 4s and 5 with small screens. Thus,
            reading the small text on the screens was challenging for them.
            </MainText1>

            {getSeperatorWithHeight(24)}

            <MainText1>
              However, a bigger font needs more space. To free more screen space,
              I proposed to only <MainText1Bold> display data in one unit </MainText1Bold>, and added a
              micro-interaction for switching units modes. In fact, after
              some user interview, I realized displaying two units was
              unnecessary. All sailors can communicate with British unit
              and almost always use it. Removing one unit from the screen
              nearly doubled the available space for information, leading
              to a larger font. Besides, to release more space, I also
              replace long words like temperature with an <MainText1Bold> icon </MainText1Bold>.
            </MainText1>

          </Col>
        </Row>




      </StyledContainer>

    )
  }
}

export default DesignChallenges1