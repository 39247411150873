import React from 'react'
import {Row, Col, Container} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight} from '../text_style'

import { AccordianMainText1, Header3, MainText1 } from './ntl_style'
import { StyledContainer } from '../layout'

class ProjectBackground extends React.Component{

  render(){
    return (

        <div>

          <AccordianMainText1> Key insights: </AccordianMainText1>


          <ul>
            <li style={{marginBottom: 0}}>
              <AccordianMainText1>
                Limnology: a study of inland water body.
                They collect data on all different aspects of the lakes from Physical to chemical to biology.

              </AccordianMainText1>  </li>
            <li style={{marginBottom: 0}}>
              <AccordianMainText1>
                NTL: A scientific grant awarded by NSF in which Center of Limnology at UW-Madison
                collects detailed information of multiple lakes in northern Wisconsin.
              </AccordianMainText1>
            </li>

            <li style={{marginBottom: 0}}>
              <AccordianMainText1>
                Data sharing: The same grant asks researchers
                to share the useful weather data collected with local communities to help public.
              </AccordianMainText1>
            </li>

          </ul>

        </div>
    )
  }

}

export default ProjectBackground


