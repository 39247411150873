import React from 'react'
import {Row, Col, Container, Collapse, Card, CardHeader, CardBody} from 'reactstrap'

import styled from 'styled-components'

import {Header2, getColOf3, getSeperatorWithHeight, HeaderForSubtitle,
  Header2WithLeftBoarderGift, HeaderForSubtitleWithLeftBorderGift} from '../text_style'

import { StyledContainer } from '../layout'

import { AnimatedText, SectionIntroHeader } from './ntl_common_component'

import {
  Header4WithLeftBorder, displayFont, displayFontWeight, textFont, textFontWeight,
} from './ntl_style'

import TrackVisibility from 'react-on-screen';
// import CompetitiveAnalysis from './competitive_analysis_accodion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProjectBackground from './ntl_project_background'
import DataVariables from './ntl_data_understanding'
import OldDesign from './ntl_old_design'
// import ProjectBackground from './interview_accordian'
// import Survey from './survey_accordion'


let CardHeaderWithHover = styled(CardHeader)`
//  Change it later
`

class DomainResearch extends React.Component {

  constructor(props){
    super(props)
    this.state={firstCollapseOpen: false, secondCollapseOpen: false, thirdCollapseOpen: false}
  }

  toggleFirst = ()=>{
    this.setState({firstCollapseOpen: !this.state.firstCollapseOpen,
    })
  }


  toggleSecond = ()=> {
    this.setState({ secondCollapseOpen: !this.state.secondCollapseOpen })
  }

  toggleThird = ()=> {
    this.setState({ thirdCollapseOpen: !this.state.thirdCollapseOpen })
  }

  render(){

    return (
      <StyledContainer  fluid={true}>
        <Row>
          {getColOf3()}
          <Col md={6}>

          <TrackVisibility>
            <AnimatedText text={"To kick off the design and development, I conducted the domain research on below topics:"}/>
          </TrackVisibility>

          </Col>

        </Row>

        {getSeperatorWithHeight(25)}
        <Row>
          {getColOf3()}
          <Col md={6}>
          <div id="accordion">
            <Card style={{borderWidth:0}}>

              {/*Card One*/}
              <CardHeaderWithHover onClick={this.toggleFirst} style={{borderWidth:0,
                 padding: "0 0 0 0" ,backgroundColor: "transparent"}} id="headingOne">

                <Header4WithLeftBorder className="hvr-fade-accordion ntl_hover"> Project Background
                  <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                   icon={ this.state.firstCollapseOpen? 'angle-up' : 'angle-down'}/>
                </Header4WithLeftBorder>

              </CardHeaderWithHover>

              <Collapse isOpen={this.state.firstCollapseOpen}>
                <CardBody >
                  <ProjectBackground/>
                </CardBody>
              </Collapse>

              {/*Card Second*/}
              <CardHeader onClick={this.toggleSecond} style={{borderWidth:0,
                backgroundColor:"transparent", padding: "0 0 0 0", marginTop: "24px"}} id="headingOne">

                <Header4WithLeftBorder className="hvr-fade-accordion ntl_hover"> Data Variables
                  <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                   icon={ this.state.secondCollapseOpen? 'angle-up' : 'angle-down'}/>
                </Header4WithLeftBorder>

              </CardHeader>

              <Collapse isOpen={this.state.secondCollapseOpen}>
                <CardBody >
                  <DataVariables/>
                </CardBody>
              </Collapse>

              {/*Card Third*/}
              <CardHeader onClick={this.toggleThird} style={{borderWidth:0,
                backgroundColor:"transparent", padding: "0 0 0 0", marginTop: "24px"}} id="headingOne">

                <Header4WithLeftBorder className="hvr-fade-accordion ntl_hover"> Old Design
                  <FontAwesomeIcon style={{position: "absolute", right: "20px"}}  size="lg"
                                   icon={ this.state.thirdCollapseOpen? 'angle-up' : 'angle-down'}/>
                </Header4WithLeftBorder>

              </CardHeader>

              <Collapse isOpen={this.state.thirdCollapseOpen}>
                <CardBody>
                  <OldDesign/>
                </CardBody>
              </Collapse>

            </Card>
          </div>
          </Col>
        </Row>

      </StyledContainer>
    )
  }

}

export default DomainResearch